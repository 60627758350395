import { notifyMessage } from 'src/constants/message';
import { notification } from 'core-ui';
import { lodash } from 'core-helpers';
import { RoutePath } from 'src/enums/routePath';
import { localeFormat } from 'src/constants/formatString';

export const handleError = (error: any) => {
  const resMessage = error?.response?.data?.message;
  const errorMessage = lodash.isString(resMessage)
    ? resMessage
    : notifyMessage.failed;

  notification.error({
    message: errorMessage
  });
};

export const replacePathParams = (path: string, params: Object): string => {
  const prefix = ':';
  let newPath = path;

  const isPathHasAnyKeyInParams = Object.keys(params).some((key) =>
    path.includes(key)
  );

  if (!isPathHasAnyKeyInParams) {
    return RoutePath.NOT_FOUND;
  }

  Object.entries(params).forEach(([key, value]) => {
    newPath = newPath.replace(prefix + key, value);
  });

  return newPath;
};

export const formatNumber = (text: number) => {
  return text?.toLocaleString(localeFormat.EN_US);
};
