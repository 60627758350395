export enum ApiPath {
  USER = '/users',
  LOGIN = '/auth/login',
  REGISTER = '/register',
  TODO = '/todos',
  RESET_PASSWORD = '/reset_password',
  UPDATE_PASSWORD = '/update_password',
  PROJECTS = '/projects',
  EXPORT_PROJECTS = '/projects/export/listprojects',

  OWNERS = '/owners',
  EXPORT_OWNERS = '/owners/export/listowners',

  PROPERTY = '/properties',
  EXPORT_PROPERTIES = '/properties/export/listproperties',

  ACCOUNT = '',
  PROFILE = '/profile',
  LOGOUT = '/auth/logout',
  TEMPLATE = '/message-templates',
  ITEM_SUGGESTION = '/suggestion/items',
  TITLE_SUGGESTION = '/suggestion/titles',
  ORDER = '/orders',

  SCHEDULES = '/schedules',
  SCHEDULE_HISTORIES = '/histories',
  NOTIFICATION = '/notifies'
}
