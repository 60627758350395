import {
  Layout,
  LeftOutlined,
  MenuProps,
  RightOutlined,
  notification,
  useLayout
} from 'core-ui';
import { FC, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardIcon } from 'src/assets/icons/DashboardIcon';
import { DatePickerIcon } from 'src/assets/icons/DatePickerIcon';
import { ListOwnerIcon } from 'src/assets/icons/ListOwnerIcon';
import { ListProperyIcon } from 'src/assets/icons/ListProperyIcon';
import { ProjectIcon } from 'src/assets/icons/ProjectIcon';
import { SettingIcon } from 'src/assets/icons/SettingIcon';
import { NOTIFICATION_KEY, SCHEDULE_KEY } from 'src/constants/queryKeys';
import { EventType } from 'src/enums/event';
import { RoutePath } from 'src/enums/routePath';
import useSubscribeEvent from 'src/hooks/useSubscribeEvent';
import { getSevenDayLater, getToday } from 'src/utils/calendar';
import Header from './components/Header';
import Logo from './components/Logo';
import './index.scss';

type MainLayoutProps = {
  children: React.ReactElement;
};

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const layout = useLayout();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const editSuggestion = location.pathname === RoutePath.EDIT_SUGGESTION;

  const [menuSelected, setMenuSelected] = useState(
    () => `/${location?.pathname?.split('/')?.[1]}`
  );

  const menuItems: MenuProps['items'] = [
    {
      key: RoutePath.DASHBOARD,
      icon: <DashboardIcon />,
      label: 'ダッシュボード',
      className: '!h-[50px]',
      style: { display: 'flex', alignItems: 'center' }
    },
    {
      key: RoutePath.LIST_OWNER,
      icon: <ListOwnerIcon />,
      label: 'オーナー管理',
      className: '!h-[50px]',
      style: { display: 'flex', alignItems: 'center' }
    },
    {
      key: RoutePath.LIST_PROPERTY,
      icon: <ListProperyIcon />,
      label: '物件管理',
      className: '!h-[50px]',
      style: { display: 'flex', alignItems: 'center' }
    },
    {
      key: RoutePath.SCHEDULE,
      icon: <DatePickerIcon />,
      label: 'スケジュール管理',
      className: '!h-[50px]',
      style: { display: 'flex', alignItems: 'center' }
    },
    {
      key: RoutePath.PROJECTS,
      icon: <ProjectIcon />,
      label: '案件管理',
      className: '!h-[50px]',
      style: { display: 'flex', alignItems: 'center' }
    },
    {
      key: RoutePath.BASIC_FEATURES,
      icon: <SettingIcon />,
      label: '基本機能管理',
      className: '!h-[50px]',
      style: { display: 'flex', alignItems: 'center' }
    },
    {
      key: '',
      icon: !layout.collapsed ? <LeftOutlined /> : <RightOutlined />,
      label: 'メニューを閉じる',
      onClick: () => {
        layout.toggleMenu();
      },
      className: '!h-[50px]',
      style: { display: 'flex', alignItems: 'center' }
    }
  ];

  const scrollToTop = () => {
    if (contentWrapperRef.current) {
      contentWrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const navigateAndSetMenuSelected = (menu: any) => {
    navigate(menu?.key);
    setMenuSelected(menu?.key);
  };

  const refetchSchedule = () => {
    queryClient.refetchQueries([
      SCHEDULE_KEY,
      { scheduled_from: getToday(), scheduled_to: getSevenDayLater() }
    ]);
  };

  const refetchNotification = () => {
    queryClient.refetchQueries([NOTIFICATION_KEY]);
  };

  const handleClickMenu = (menu: any) => {
    if (!menu?.key || menu?.key === '') {
      return;
    }

    notification.destroy();

    navigateAndSetMenuSelected(menu);

    switch (menu.key) {
      case RoutePath.SCHEDULE:
        refetchSchedule();
        break;
      case RoutePath.DASHBOARD:
        refetchNotification();
        break;
      default:
        break;
    }
  };

  const handleLocationChange = () => {
    scrollToTop();
    setMenuSelected(`/${location?.pathname?.split('/')?.[1]}`);
  };

  useEffect(() => {
    handleLocationChange();
  }, [contentWrapperRef, location]);

  useSubscribeEvent([
    {
      type: EventType.SCROLL_TO_TOP,
      listener: scrollToTop
    }
  ]);

  return (
    <Layout
      layout={layout}
      headerContent={<Header />}
      logoContent={<Logo collapsed={layout.collapsed} />}
      logoClassName="flex justify-center items-center !bg-primary-400 !h-[70px]"
      menuItems={menuItems}
      siderStyle={{
        color: '#fff'
      }}
      menuProps={{
        selectedKeys: [menuSelected],
        className: '!overflow-hidden',
        onClick: (menu) => handleClickMenu(menu)
      }}
      contentWrapperProps={{
        ref: contentWrapperRef
      }}
      hideCollapser={true}
      contentWrapperClassName={`${editSuggestion === true ? '!p-0 lg:!pl-[28px] lg:!pr-[92px] bg-red-500 !pr-[30px]' : '!p-0 lg:!pl-[88px] lg:!pr-[92px] !px-[30px] '} !bg-white !pb-[50px]`}
      siderClassName="text-for-all"
      style={{ background: '#fff' }}
    >
      {children}
    </Layout>
  );
};

export default MainLayout;
