export const REMOVE_WHITESPACE_AND_NUMBERS = /[\s0-9]/g;
export const REMOVE_LEADING_ZEROS = /^0+(?=[1-9])/g;
export const REMOVE_NON_NUMBERS = /[^-\d]/g;
export const IS_VALID_POSTAL_CODE = /^[0-9]{7}$/;
export const IS_VALID_EMAIL =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const IS_VALID_PHONE_NUMBER = /^\d{10,11}$/;
export const IS_VALID_FAX_NUMBER = /^\d{2}-\d{4}-\d{4}$/;
export const IS_VALID_NAME = /^[\u4E00-\u9FAF\u0041-\u005A\u0061-\u007A\s]+$/;
export const IS_VALID_KATAKANA = /^[\u30A1-\u30F6\uFF66-\uFF9D\s]+$/;
export const IS_VALID_EXACT_13_CHARS = /^.{13}$/;
export const IS_VALID_EXACT_100_CHARS = /^.{0,100}$/;
export const IS_VALID_MIN_12_CHARS = /^.{12,}$/;
export const IS_FULL_WHITESPACE = /^\s*$/;
export const REMOVE_WHITESPACE = /\s+/g;
export const IS_NUMBER = /^\d+$/;
export const IS_VALID_KATAKANA_SPACE = /^([ァ-ン 　]|ー)+$/;
export const DUPLICATED_HOST_CODE = '家主コードは既に使用されています。';
export const IS_VALID_MAX_CHARS = (maxLength: number) =>
  new RegExp(`^.{0,${maxLength}}$`);
export const REMOVE_COMMA = /,/g;
export const REMOVE_LINE_BREAK = /\n/g;
export const IS_VALID_MAX_10_CHARS = /^.{1,10}$/;
